a {
  color: white !important;
}

a:hover {
  text-decoration: none !important;
}

.social-svg {
  border-radius: 2px !important;
}

.social-icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px;
}

.avatar {
  text-align: center;
  margin: auto;
}

.profile {
  text-align: center;
  max-width: 600px;
}

button {
  width: 200px;
}
