.profile-image {
  position: relative;
}

.profile-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: #000000FF;
  border-radius: 50%;
}

.profile-image:hover .profile-image-overlay {
  opacity: 0.8;
}

.profile-image-delete-icon {
  color: #F44336;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
