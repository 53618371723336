body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.logo-small {
  text-align: center;
  padding-bottom: 10px;
}

.logo-small img {
  width: 48px;
  padding-right: 10px;
}

.loading {
  min-height: 25%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 25vh;
}
