// change the theme
$theme-colors: (
  "blue": #0660DB,
  "indigo": #6610f2,
  "purple": #6f42c1,
  "pink": #e83e8c,
  "red": #F44336,
  "orange": #fd7e14,
  "yellow": #ffc107,
  "green": #00E676,
  "teal": #8AC9E7,
  "cyan": #17a2b8,
  "white": #fff,
  "gray": #AFC4CE,
  "gray-dark": #7F99B2,
  "primary": #0660DB,
  "secondary": #AFC4CE,
  "success": #28a745,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #F44336,
  "light": #EFF5FB,
  "dark": #003366,
);

$body-bg: #E1E7EA;
$jumbotron-bg: #E1E7EA;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';