.firebaseui-container {
  background-color: #E1E7EA;
  margin-bottom: 15px;
  min-height: 150px;
  padding-top: 10px;
  border-radius: 40px;
  box-shadow: none !important;
}

.firebaseui-card-header {
  display: none;
}

.firebaseui-id-submit {
  background-color: #0660DB !important;
  box-shadow: none !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}
